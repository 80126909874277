import type {ExtraInfo} from './types';

const DATA: ExtraInfo = {
  details: [
    {
      type: 'description',
      heading: 'イベント概要',
      paragraphs: [
        {
          content:
            'allizは、キャリアデザインとキャリア開発に焦点を当てた、メンバーが運営するキャリアメンターシップコミュニティです。allizでは、コミュニティ主導の体験を通じて、メンターシップを育み、価値あるつながりを築くことに力を入れています。allizの独自のアプローチを通じて、学生、キャリア初期の社会人、さまざまなテック関連業界のメンターがつながり合い、互いの経験や知識、アドバイスを共有することで、参加者同士の成長と学びを促進しています。',
        },
        {
          content:
            '最近では日本においてデジタルトランスフォーメーション（DX）の潮流が急速に進化しており、テクノロジーが産業の再構築において重要な役割を果たしています。その中で、コンサルティングはDX推進のための指針を示し、ビジネスのデジタル統合を支える要となっています。「Consulting 101 - Bloom Your Own Color」は、allizとARK Consulting株式会社が共催する特別なイベントです。ARK Consultingは、クライアントのビジネス成長を支援するために設立されたコンサルティング会社で、SAPの導入コンサルティングをはじめ、全般的なITソリューションの提案を行っています。',
        },
        {
          content:
            '本イベントでは、DX市場のトレンドやコンサルティング業界の文化についてお話します。日本におけるDXの最新動向や進化するコンサルティングの役割に関する講演に加え、コンサルティング分野でのキャリアパスや成長機会、メンターシップについてのパネルディスカッションも行います。業界の仲間と情報を共有し、学びを得るだけでなく、ネットワークを広げる絶好のチャンスです。',
        },
        {
          content:
            '技術的なバックグラウンドを持ち、デジタルトランスフォーメーションに情熱を抱いている方がコンサルティングのキャリアを築くために最適なイベントです。イベント名にもあるように「Consulting 101」では、経験の有無を問わず、すべての参加者を歓迎します。社会人から学生まで、コンサルタントがどのようにして技術革新を推進するのかについて、貴重な洞察を得られる機会です。軽食やドリンクをご用意していますので、お気軽にご参加ください！またドレスコードは特にありません。',
        },
      ],
    },
    {
      type: 'list',
      heading: 'こんな方におすすめ',
      items: [
        'キャリアアドバイスが欲しいと考えている若手のコンサルタント',
        'テクノロジーまたはコンサルティング分野へのキャリアに関心のある社会人や学生',
        'デジタルトランスフォーメーションに関心がある社会人や学生',
        'メンターシップの機会を求める社会人や学生',
        'プロフェッショナルネットワークを広げたいと考えている社会人や学生',
      ],
    },
    {
      type: 'description',
      heading: '登壇者・トピック紹介',
      paragraphs: [
        {
          heading:
            'スピーカー①：バイエルホールディング株式会社 IT Country Manager & Digital Lead  文 迈原氏 ',
          content:
            'Michael(マイケル) と申します。日本に15年住んでおり、eコマース分野で11年間働いています。IT戦略、プロダクト管理、プロジェクト管理を専門としています。楽天では戦略部、Marketing Platformで3年間働き、その後はAmazonの FBAとAdvertistingで4年間プロダクトマネージャーとして働きました。現在はバイエル日本のコンシューマーヘルスでIT Country Manager & APAC e-com Digital Leadとして働いており、eコマースを中心としたDXへの転換を担当しています。趣味はスノボ、スキー、ダイビング、ドライブです。週末はスノボのインストラクターやBCガイドもしています。よろしくお願いします。',
        },
        {
          heading: 'トピック①：「日本におけるDX」',
          content:
            '本セッションでは、日本におけるデジタルトランスフォーメーションの現状、課題、機会について探ります。グローバルなデジタル経済の急速な発展に伴い、日本も新しい市場環境や消費者のニーズに適応するために、積極的にデジタルトランスフォーメーションを推進しています。本セッションでは、日本企業の取り組みを分析し、政府による政策支援の役割を考察するとともに、DXを促進する技術的要因について詳しく解説します。また、文化的な壁、スキル不足、データセキュリティの問題といった課題にも焦点を当てます。成功事例の分析を通じて、日本におけるデジタルトランスフォーメーションの未来の可能性を見据えます。',
        },
        {
          heading:
            'スピーカー②：ARK Consulting株式会社 Senior Consultant 北川 英弘 氏',
          content:
            'SAP導入コンサルタントとしてプロジェクトに参画する傍ら、ARK Consultingの採用担当を務めています。 早稲田大学卒業後、大手メーカ子会社などで多数のSAP導入プロジェクトを経験し、ロジ系モジュール導入をメインに行ってきました。 また、採用担当としては、採用プロセスの見直しや、採用HPの刷新などを行っています。 趣味は登山、ロードバイク、読書、料理など。読書は、経営理論や心理学、物理学関連(読み物的なモノ)を読むことが多いです。',
        },
        {
          heading:
            'トピック②：「SAP社のDXソリューションとARK ConsultingのMission」',
          content:
            '本セッションの前半では、企業がデジタルトランスフォーメーションを実現するために、SAP社が提供しているソリューションの具体的な事例を紹介します。 SAP SapphireやSAP TechEd JAPAN 2024で展開された情報をベースに、SAP社がAIをどのように活用しようとしているのか、またUXがどのように変わろうとしているのか等について話します。 後半では、少し俯瞰的な視点で、デジタルトランスフォーメーションの動向と、ARK CONSULTINGが今年、見直した新しい会社Mission “Bloom Your Own Color”についてお話しします。',
        },
        {
          content: '',
        },
        {
          content: '',
        },
        {
          heading:
            'パネリスト①：SAPジャパン株式会社 Associate Technical Consultant 原田 佑介 氏',
          content:
            'マダガスカルで幼少期を過ごし、インターナショナルスクールに通学。2024年に上智大学を卒業後、SAPジャパンに入社。調達購買ソフトウェア「Ariba」の導入や保守運用を担当し、業務プロセスの最適化を支援している。さらに、業務外でも社内イベントの企画や運営に携わり、チームの活性化や社員間の交流促進に貢献。国際的な環境で育んだコミュニケーション力と柔軟性を活かし、幅広い業務で成果を上げ続けている。',
        },
        {
          heading:
            'パネリスト②：ARK Consulting株式会社 Consultant オリベイラ カレン 氏',
          content:
            '生まれ育ちが日本の純ブラジル人です。最初はサービス業界に勤めていましたが、そこからIT業界へ、後にSAPの業界へと移りました。ARKには未経験として入社し、SAPコンサルタントとして2年目を迎え、導入支援の経験を積んでいます。現在はコードマスタチームというデータ定義やデータルールを管理・統括するチームの一員としてクライアントのサポートをしております。「伝える力」を磨けるこの仕事にやりがいを感じています。',
        },
        {
          heading:
            'パネリスト③：ARK Consulting株式会社 Senior Consultant 王 禹 氏',
          content:
            '私は東京でITコンサルタントとして働き、SAP導入支援を担当しています。日本での8年間のキャリアで、多くの日本企業のプロジェクトに携わってきました。中国の大学で日本語を学んだ後、名古屋で修士号を取得し、東京で就職。現在はクラウドやプログラミングを独学し、スキル向上に努めています。',
        },
      ],
    },
    {
      type: 'schedule',
      heading: 'タイムテーブル',
      items: [
        {
          timeUTC: 1740558600,
          description: '受付開始 (受付は18:30まで)',
        },
        {
          timeUTC: 1740560400,
          description: 'オープニング、ネットワーキング',
        },
        {
          timeUTC: 1740562200,
          description: 'ゲストトーク＃１：「日本におけるDX」',
          info: [
            'バイエルホールディング株式会社 IT Country Manager & Digital Lead  文 迈原氏',
          ],
        },
        {
          timeUTC: 1740563100,
          description:
            'ゲストトーク＃２：「SAP社のDXソリューションとARK ConsultingのMission」',
          info: ['ARK Consulting株式会社 Senior Consultant 北川 英弘 氏'],
        },
        {
          timeUTC: 1740564000,
          description: 'Q&A ',
        },
        {
          timeUTC: 1740564900,
          description: '休憩',
        },
        {
          timeUTC: 1740565500,
          description:
            'パネルディスカッション：「コンサルタントとしてのキャリア',
          info: [
            'SAPジャパン株式会社 Associate Technical Consultant 原田 佑介 氏',
            'ARK Consulting株式会社 Consultant オリベイラ カレン 氏',
            'ARK Consulting株式会社 Senior Consultant 王 禹 氏',
          ],
        },
        {
          timeUTC: 1740567600,
          description: 'ネットワーキング',
        },
        {
          timeUTC: 1740570300,
          description: 'クロージング',
        },
        {
          timeUTC: 1740571200,
          description: '閉会',
        },
      ],
    },
    {
      type: 'description',
      heading: '注意事項',
      paragraphs: [
        {
          content:
            '申し込み：本イベントは参加無料です。定員は100名です。申し込み完了次第、カレンダーの招待メールをお送りします。申し込み締切は2025年2月24日（月）です。',
        },
        {
          content:
            '言語：イベントは日本語で行われますが、必要に応じて英語を使用する場合があります。ネットワーキングの時間は特に言語の指定はありません。',
        },
        {
          content:
            '軽食・お飲み物：会場には軽食とお飲み物をご用意しています。アレルギーのある方はご自身にてご配慮いただきますようお願いします。',
        },
        {
          content:
            '写真撮影：イベント中に写真やビデオが撮影され、allizのウェブサイトやソーシャルメディアで宣伝目的で使用される場合があります。また、本イベントに登録することで、これらの写真やビデオの使用許可を与えることになります。写真撮影には了承できないけれども、イベントに参加したいという場合には、allizにご連絡ください。',
        },
        {
          content: 'スケジュール：スケジュールは当日変更になる場合があります。',
        },
        {
          content:
            'お問い合わせ：イベントに関するお問い合わせは、alliz（event@alliz.jp）までメールでお気軽にご連絡ください。皆様のご参加を心よりお待ちしております。',
        },
      ],
    },
  ],
  tags: [],
  thumbnailURL:
    '/static/images/kMASOkQt47/1CDR5nmNdsdoLh1waJLLeccyCcVlPK9Q.png',
};

export default DATA;
